.particlesContainer{
    width: 100%;
    height: 100vh;
    background: black;
}

.particlesTextContainer{
    position: absolute;
    top: 40vh;
    left: 10%;
    
}
.particlesContainer h1{
    color: white;
    font-weight: 300;
    font-size: 4.8rem;
    float: left;
}
.selfWritingText{
    color: #F6184D;
    float: right;
    margin-left: 1rem;
}
.particlesTextContainerHeadline{
    width: 100%;
}
.particlesTextContainerP{
    margin-top:0.7rem;
    width: 100%;
    float: left;
}
.particlesTextContainerP > p {
    float: left;
    color: white;
   width: 40rem;
   font-size: 1.6rem;
}

.particlesTextContainerImg img {
    width: 3rem;
    margin-top: 2rem;
    margin-left: 1.3rem;
    float: left;
}
.scroller {
color: white;
font-size: 2rem;
position: absolute;
top: 87%;
width: 100%;
text-align: center;
animation: moveScroll 2.5s infinite;
}

@keyframes moveScroll{
    0% {
        top: 87%
    }
    50%{
        top: 90%;
    }
    100%{
        top: 87%;
    }
}

@media only screen and (max-width: 900px) {
    .particlesContainer h1{
        font-size: 3.8rem;
}
}
@media only screen and (max-width: 600px) {
    .particlesContainer h1{
        font-size: 2.5rem;
}
    .particlesTextContainerP > p {
       width: 70%;
       font-size: 1.4rem;
    }
    .scroller {
        font-size: 1.5rem;
        }
}

@media only screen and (min-width: 3840px) {
    .particlesContainer h1{
        font-size: 9rem;
    }
    .particlesTextContainerP > p {
       font-size: 4rem;
    }
    .particlesTextContainerImg img {
        width: 7rem;
    }
    .scroller {
        font-size: 4rem;
    }
}