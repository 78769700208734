.main{
    background-color: black;
    color: white;
}
.container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    justify-items: center;
}
.main > h1{
    font-size: 3.6rem;
    font-weight: 400;
    padding-top: 10.2rem;
    padding-bottom: 5.9rem;
    width: 100%;
    text-align: center;
}

.skills > h2{
font-family: Roboto Mono;
font-weight: 400;
margin-top: 5.2rem;
font-size: 2.4rem;

}
.skills > p{
    font-size: 1.8rem;
    margin-top: 1.4rem;
    width: 80%;
}
.skills > a {
    color: #F6184D;
    text-decoration: underline;
    font-size: 1.8rem;
    margin-top: 20rem;
}
.experienceContainer > h2{
font-size: 2.4rem;
font-family: Roboto Mono;
font-weight: 400;
margin-top: 6rem;
}
.experience{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.experience > img{
    margin-top: 4.9rem;

    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;  
}
.expData{
    margin-top: 1.6rem;
}
.expData > h2{
margin-top: 7.4rem;
font-size: 1.8rem;
}
.expData > p{
    font-size: 1.4rem;
    margin-top: .8rem;
}
.copyright{
    width: 100%;
    text-align: center;
    margin-top: 10vh;
}
@media only screen and (max-width: 900px) {
    .main > h1{
        font-size: 3rem;
    }
    .skills{
        margin-left: 3rem;
    }
    .skills > h2{
        font-size: 2rem;
    }
    .skills > p {
        font-size: 1.6rem;
    }
    .skills > a {
        font-size: 1.6rem;
    }
    .experienceContainer > h2{
        font-size: 2rem;
        }
    .expData{
        margin-right: 3rem;
    }
    .expData > h2{
    margin-top: 7.4rem;
    font-size: 1.6rem;
    }
    .expData > p{
        font-size: 1.4rem;
        margin-top: .8rem;
    }
        
}
@media only screen and (max-width: 600px) {
    .container{
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        justify-items: center;
    }
    .expData{
        margin-right: auto;
    }
}
@media only screen and (min-width: 3840px) {
    .container{
       width: 40%;
       margin-left: 30%;
    }
}