*{
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

html{
  /*10px*/
  font-size: 62.5%;
  font-family: Roboto, sans-serif;
}
body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Roboto Condensed', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    font-family: 'Roboto Condensed','Roboto Mono',
    sans-serif, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
