.main{
    background-color: #0F0F0F;
}
.carousel{
    display: grid;
    grid-template-columns: 37.7rem 37.7rem 37.7rem;
    grid-template-rows: auto;
    column-gap: 10rem;
    justify-items: center;
    justify-content: center;
}
.componentHeadline{
font-size: 3.6rem;
font-weight: 400;
color: white;
margin-left: 10.5rem;
padding-top: 10.6rem;
}
.container{
    background-color: #141414;
    margin-top: 5.8rem;
    width:37.7rem;
    border-radius: .6rem;
    cursor: pointer;

    -webkit-box-shadow: 1px 3px 100px 0px rgba(0,0,0,1);
-moz-box-shadow: 1px 3px 100px 0px rgba(0,0,0,1);
box-shadow: 1px 3px 100px 0px rgba(0,0,0,1);
}
.container > img{
    width: 37.7rem;
    height: 20.5rem;
    border-radius: .6rem .6rem 0 0;

    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;  
}
.textPart{
    margin-left: 3rem;
}
.tag{
font-size: 1.3rem;
color: #F6184D;
margin-top: 1.1rem;
}
.textPart > h1{
    font-size: 2rem;
    font-weight: 400;
    margin-top: .8rem;
    color:white;
}
.perex{
    font-size: 1.6rem;
    color:white;
    margin-top: .9rem;
    max-width: 80%;
    height: 13.5rem;
}
.textPart > button{
    border: .2rem solid #F6184D;
    background-color: #141414;
    color: #F6184D;
    font-size: 1.3rem;
    width: 13.2rem;
    height: 3.7rem;
    margin-top: 2rem;
    margin-bottom: 2.7rem;
    border-radius: .4rem;
}
.textPart > button:hover{
    background-color: #F6184D;
    color: #141414;
    cursor: pointer;
}
.spacer{
    height: 6.11rem;
}
.popupContainer{
    width: 70%;
    min-height: 55rem;
    margin-left: 15%;
    border-radius: 1.5rem;

    background-color: #141414;
    color: white;

    position: fixed;
    top: 15%;

    -webkit-box-shadow: 1px 1px 100px 0px rgba(0,0,0,1);
    -moz-box-shadow: 1px 1px 100px 0px rgba(0,0,0,1);
    box-shadow: 1px 1px 100px 0px rgba(0,0,0,1);
}
.cancelButton{
    width:1.5%;
    float: right;
    margin-right: 2rem;
    margin-top: 2rem;
    cursor: pointer;
}
.popupData{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 6rem;
    margin-left: 5%;
    width: 90%;
    
}
.popupData img{
    width: 60rem;
    margin-top: 3rem;


    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: .6rem;
}
.innerData{
    width: 95%;
    margin-left: 10rem;
}
.innerData > h1{
    font-family: Roboto Mono;
    font-size: 3rem;
    font-weight: 400;
}
.innerData > .description{
    font-size: 1.6rem;
    margin-top: 2.1rem;
    width: 80%;
}
.innerData > .tag{
    font-size: 1.4rem;
    color: #F6184D;
}
.innerData > img{
    width: 6%;
    margin-right: 1rem;
    margin-top: 2rem;
}
.links button{
border: 2px solid #F6184D;
color: white;
background-color: #141414;
padding: 1rem 4.45rem;
font-size: 1.8rem;
margin-top: 2rem;
margin-right: 2.1rem;
border-radius: 5px;
}
.links button:hover{
    background-color: #F6184D;
    color: #141414;
    cursor: pointer;
}
@media only screen and (max-width: 1600px) {
    .carousel{
        display: grid;
        grid-template-columns: 37.7rem 37.7rem 37.7rem;
        column-gap: 4rem;
    }
    .container{
        width: 30rem;
    }
    .perex{
        font-size: 1.4rem;
    }
    .container > img{
        width: 30rem;
        height: auto;
    }
    .popupContainer{
        height: 50.2rem;
    }
    .popupData{
        margin-top:6rem;
        width: 90%;
    }
    .innerData > .description{
        width:80%;
        font-size: 1.4rem;
    }
    .popupData img{
    width: 100%;
    }
    .innerData > img{
        width: 6%;
    }
    .links button{
        border: 2px solid #F6184D;
        color: white;
        background-color: #141414;
        padding: .8rem 3.45rem;
        font-size: 1.8rem;
        margin-top: 3.7rem;
        margin-right: 2.1rem;
        border-radius: 5px;
        }
}
@media only screen and (max-width: 1200px) {
    .carousel{
        display: grid;
        grid-template-columns: 37.7rem 37.7rem;
        column-gap: 5rem;
    }
    .container{
        width: 37.7rem;
    }
    .perex{
        font-size: 1.4rem;
    }
    .container > img{
        width: 37.7rem;
        height: auto;
    }
    .popupData{
        margin-left: 5%;
    }
    .popupContainer{
        width: 90%;
        margin-left: 5%;
    }
    .links button{
        border: 2px solid #F6184D;
        color: white;
        background-color: #141414;
        padding: .7rem 3rem;
        font-size: 1.8rem;
        margin-top: 3.7rem;
        margin-right: 2.1rem;
        border-radius: 5px;
        }
}
@media only screen and (max-width: 900px) {
    .carousel{
        display: grid;
        grid-template-columns: 37.7rem;
        column-gap: auto;
    }
    .popupContainer{
        height: 80rem;
    }
    .popupData{
        display: grid;
        grid-template-columns: 1fr;
        margin: 0;
        width: 100%;

    }
    .popupData > img{
        width: 80%;
        margin-top: 1rem;
        margin-left: 10%;
        border-radius: .6rem;
        }
    .innerData{
            width:80%;
            font-size: 1.4rem;
            margin-top: 3rem;
        }
    .innerData > h1{
        font-size: 2.5rem;
    }
    .innerData > .tag{
        font-size: 1.2rem;
    }
    .cancelButton{
        float: right;
        margin-right: 2rem;
        margin-top: 2rem;
        cursor: pointer;
        width:3%;
        
    }
    .links button{
        border: 2px solid #F6184D;
        color: white;
        background-color: #141414;
        padding: .7rem 3rem;
        font-size: 1.6rem;
        margin-top: 3.7rem;
        margin-right: 2.1rem;
        border-radius: 5px;
        }
       
}
@media only screen and (max-width: 600px) {
    .carousel{
        display: grid;
        grid-template-columns: 30rem;
    }
    .container{
        width: 30rem;
    }
    .container > img{
        width: 30rem;
        height: auto;
    }
    .links button{
        border: 2px solid #F6184D;
        color: white;
        background-color: #141414;
        padding: .7rem 3rem;
        font-size: 1.6rem;
        margin-top: 1.5rem;
        margin-right: 2.1rem;
        border-radius: 5px;
        width: 80%;
        }
        .popupContainer{
            height: 60rem;
            top: 5%;

        }
        .innerData{
            margin-left: 10%;
            
        }
        .innerData>.description{
            font-size: 1.2rem;
            width: 100%;
        }
        
}
@media only screen and (min-width: 2080px) {
    .popupData > img{
        width: 80rem;
        margin-top: 3rem;
    }
}
@media only screen and (min-width: 3840px) {
    .carousel{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        column-gap: 5rem;
        justify-items: center;
        justify-content: center;
        width: 70%;
        margin-left: 25%;
    }
    .componentHeadline{
        font-size: 3.6rem;
        font-weight: 400;
        color: white;
        margin-left: 80.5rem;
        padding-top: 10.6rem;
        }
        .container{
            background-color: #141414;
            margin-top: 5.8rem;
            width:60rem;
            border-radius: .6rem;
            cursor: pointer;
        
            -webkit-box-shadow: 1px 3px 100px 0px rgba(0,0,0,1);
        -moz-box-shadow: 1px 3px 100px 0px rgba(0,0,0,1);
        box-shadow: 1px 3px 100px 0px rgba(0,0,0,1);
        }
        .container > img{
            width: 60rem;
            height: auto;
            border-radius: .6rem .6rem 0 0;
        
            user-drag: none; 
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;  
        }
        .textPart{
            margin-left: 3rem;
        }
        .tag{
        font-size: 2rem;
        color: #F6184D;
        margin-top: 3.1rem;
        }
        .textPart > h1{
            font-size: 4rem;
            font-weight: 400;
            margin-top: .8rem;
            color:white;
        }
        .perex{
            font-size: 2.5rem;
            color:white;
            margin-top: .9rem;
            height: auto;
        }
        .textPart > button{
            border: .2rem solid #F6184D;
            background-color: #141414;
            color: #F6184D;
            font-size: 1.3rem;
            width: 13.2rem;
            height: 3.7rem;
            margin-top: 2rem;
            margin-bottom: 2.7rem;
            border-radius: .4rem;
        }
        .textPart > button:hover{
            background-color: #F6184D;
            color: #141414;
            cursor: pointer;
        }
        .popupContainer{
            width: 60%;
            height: 90rem;
            margin-left: 25%;
            border-radius: 1.5rem;
        
            background-color: #141414;
            color: white;
        
            position: fixed;
            top: 15%;
        
            -webkit-box-shadow: 1px 1px 100px 0px rgba(0,0,0,1);
            -moz-box-shadow: 1px 1px 100px 0px rgba(0,0,0,1);
            box-shadow: 1px 1px 100px 0px rgba(0,0,0,1);
        }
        .cancelButton{
            width:1.5%;
            float: right;
            margin-right: 2rem;
            margin-top: 2rem;
            cursor: pointer;
        }
        .popupData{
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top:15.4rem;
            margin-left: 10%;
            width: 90%;
            
        }
        .popupData img{
            width: 100%;
        
        
            user-drag: none; 
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            border-radius: .6rem .6rem 0 0;
        }
        .innerData{
            width: 80%;
            margin-left: 15%;
        }
        .innerData > h1{
            font-family: Roboto Mono;
            font-size: 5rem;
            font-weight: 400;
        }
        .innerData > .description{
            font-size: 1.6rem;
            margin-top: 2.1rem;
            width: 80%;
        }
        .innerData > .tag{
            font-size: 2rem;
            color: #F6184D;
        }
        .innerData > .description{
            font-size: 2.5rem;
        }
        .innerData > img{
            width: 6%;
            margin-right: 1rem;
            margin-top: 3.6rem;
        }
        .links button{
        border: 2px solid #F6184D;
        color: white;
        background-color: #141414;
       width: 25rem;
       height: 5rem;
        font-size: 2rem;
        margin-top: 3.7rem;
        margin-right: 2.1rem;
        border-radius: 5px;
        }
        .links button:hover{
            background-color: #F6184D;
            color: #141414;
            cursor: pointer;
        }
}