.header{
    width: 100%;
    height: 6rem;
    display: block;
    background-color: black;
    position: fixed;
}
.innerHeader{
    width: 80%;
    height: 100%;
    display: block;
    margin: 0 auto;
}
/*Language Selector*/
.languageContainer{
    height: 100%;
    float: left;
}
.languageContainer > select{
background-color: black;
border: none;
font-size: 1.5rem;
margin-top: calc(3rem - 1.5rem / 2);
color: white;
display: flex;
}
/*NAV*/
.navContainer{
    float: right;
}
.navContainer > nav{
    display: flex;
    align-items: center;
}
.navContainer > nav > a{
    font-size: 1.5rem;
    margin-right: 3.9rem;
    color: white;
    margin-top: calc(3rem - 1.5rem / 2);
    font-family: 'Roboto Mono'; 
}
@media only screen and (max-width: 600px) {
    .navContainer > nav > a{
        font-size: 1.3rem;
        margin-right: 1rem;
    }
}
@media only screen and (min-width: 3840px) {
    .navContainer > nav > a{
        margin-top: calc(6rem - 1.5rem / 2);
        font-size: 3rem;
    }
    .languageContainer > select{
        font-size: 3rem;
        margin-top: calc(6rem - 1.5rem / 2);
    }
}